<section class="navbar">
  <nav class="navbar navbar-expand-lg navbar-light bg-white container my-4">
    <a class="navbar-brand" href="#">
      <img src="../../assets/images/sodic.png" alt="" class="img-fluid" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <a class="nav-link btn shadow-none" [routerLink]="['']" routerLinkActive="router-link-active" >Home </a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('about')"
            >About</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('gallery')"
            >Gallery</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('logos')"
            >Projects</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('contact')"
            >Contact Us</a
          >
        </li>
      </ul>
    </div>
    <ul class="navbar-nav  ">
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/ar']"
          routerLinkActive="router-link-active"
          >Arabic</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link call" href="tel:01001040090"
          >Call Us : 01001040090</a
        >
      </li>
    </ul>
  </nav>
</section>

<section class="header">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 my-2">
        <h1>The society everyone wants to be a part of</h1>
        <p>
          Building a history of more than two decades of successful operation in
          Egypt, SODIC is one of the country’s leading real estate development
          companies.
        </p>
        <button class="btn" (click)="scrollTo('contact')">EXPLORE</button>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-5 mt-2">
        <div class="form">
          <form [formGroup]="contactForm"  (ngSubmit)="onSubmit()" >
            <div class="container">
              <div class="mt-2">
                <input class="form-control" type="text" placeholder="Name" formControlName="name" />
                <app-fielderror [displayError]="isFieldValid('name')" errorMsg='Please enter a valid name'>
                </app-fielderror>
              </div>
              <div class="mt-2">
                <input class="form-control" type="number" placeholder="Phone" formControlName="phone" />
                <app-fielderror [displayError]="isFieldValid('phone')" errorMsg="Please enter a valid phone number"></app-fielderror>
              </div>
              <div class="mt-2">
                <input class="form-control" type="email" placeholder="Email" formControlName="email" />
                <app-fielderror [displayError]="isFieldValid('email')" errorMsg="Please enter a valid email address"></app-fielderror>
              </div>
            </div>
            <button type="submit" class="btn-form">Sign Up Now</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="about">
  <div class="container">
    <h1><span style="color: #f15922">ABOUT</span> SODIC</h1>
    <p>
      Building a history of more than two decades of successful operation in
      Egypt, SODIC is one of the country’s leading real estate evelopment
      companies. Headquartered in Cairo and listed on the Egyptian stock
      exchange, SODIC brings to the market award winning large-scale
      developments, meeting Egypt’s ever-growing need for high quality housing,
      commercial and retail spaces. We pride ourselves on our passion for
      excellence and commitment to fostering long-term relationships with our
      clients, shareholders, business partners and employees, which has helped
      us grow to the corporation we are today.
    </p>
  </div>
</section>

<section class="gallery">
  <div class="container">
    <h2>THE MORE YOU LIVE</h2>
    <h1>SODIC PROJECTS</h1>
    <div class="row">
      <div class="col-md-4 mt-3">
        <img
          src="../../assets/images/gallery/1.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-8  ">
        <div class="row">
          <div class="col-md-7 px-2 mt-3">
            <img
              src="../../assets/images/gallery/2.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-md-5 px-2 mt-3">
            <img
              src="../../assets/images/gallery/3.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 px-2 mt-3">
            <img
              src="../../assets/images/gallery/4.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-md-7 px-2 mt-3">
            <img
              src="../../assets/images/gallery/5.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <img
      src="../../assets/images/gallery/6.png"
      alt=""
      class="img-fluid w-100 mt-3"
    />
  </div>
</section>

<section class="logos">
  <div class="container">
    <div class="row">
      <div class="col-md-2 col-6">
        <img src="../../assets/images/logos/2.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6">
        <img src="../../assets/images/logos/2-1.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-2.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/June-SalesPresentation-FINAL-compressed-2-1024x628.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-3.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-4.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-5.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6">
        <img src="../../assets/images/logos/2-6.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-7.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-8.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-9.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-10.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6">
        <img
          src="../../assets/images/logos/2-11.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-12.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-13.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-14.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-15.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6">
        <img
          src="../../assets/images/logos/2-16.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-17.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-18.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-19.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-20.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6">
        <img
          src="../../assets/images/logos/2-21.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-22.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-23.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-24.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-25.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6">
        <img
          src="../../assets/images/logos/2-26.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-27.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>

    <div class="text-center my-5">
        <iframe class="" width="70%" height="727"  src="https://www.youtube.com/embed/u8IsD4Ini3Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

  </div>
</section>

<section class="contact">
  <div class="container">
    <h1>Contact Us</h1>
    <p>
      1996. SODIC established as a publicly traded company with more than 6,000
      shareholders and a paid-in capital of EGP 100 million. The company
      purchases its first 10 million sqm plot of land in Sheikh Zayed as part of
      its founders' vision of developing a residential community on the western
      outskirts of Cairo.
    </p>
    <div class="form">
      <form [formGroup]="contactForm"  (ngSubmit)="onSubmit()">
        <div class="mt-4">
            <input class="form-control  shadow-none" type="text" placeholder="Name" formControlName="name" />
            <app-fielderror [displayError]="isFieldValid('name')" errorMsg='Please enter a valid name'>
            </app-fielderror>
          </div>
          <div class="mt-4">
            <input class="form-control shadow-none" type="number" placeholder="Phone" formControlName="phone" />
            <app-fielderror [displayError]="isFieldValid('phone')" errorMsg="Please enter a valid phone number"></app-fielderror>
          </div>
          <div class="mt-4">
            <input class="form-control shadow-none" type="email" placeholder="Email" formControlName="email" />
            <app-fielderror [displayError]="isFieldValid('email')" errorMsg="Please enter a valid email address"></app-fielderror>
          </div>
        <div class="mt-4">
          <textarea name="note" id="" rows="3" class="w-100 form-control shadow-none" placeholder="Message" formControlName="note"></textarea>
        </div>
        <div class="text-center mt-5">
          <button type="submit" class="btn-form">Contact Us</button>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="footer">
    <div class="container">
        <div class="d-flex align-items-center flex-wrap">
            <div>
                <img src="../../assets/images/main-logo.png" alt="" class="img-fluid">
            </div>
            <div class="ml-md-auto ml-0 mt-md-0 mt-3 " >
                <p>Contact Us </p>
                <a href="tel:+201001040090">+201001040090</a>
            </div>
        </div>
    </div>
</section>
