<section class="navbar">
  <nav class="navbar navbar-expand-lg navbar-light bg-white container my-4">
    <a class="navbar-brand" href="#">
      <img src="../../assets/images/sodic.png" alt="" class="img-fluid" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <a class="nav-link btn shadow-none" [routerLink]="['/ar']" routerLinkActive="router-link-active" >الصفحة الرئيسية </a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('about')"
            >عننا</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('gallery')"
            >الصور</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('logos')"
            >المشاريع</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('contact')"
            >اتصل بنا</a
          >
        </li>
      </ul>
    </div>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['']"
          routerLinkActive="router-link-active"
          >English</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link call" href="tel:+201001040090"
          >اتصل بنا: 01001040090</a
        >
      </li>
    </ul>
  </nav>
</section>

<section class="header" dir="rtl">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 my-2">
        <h1>
          المجتمع الذي يريد الجميع أن يكون جزءًا منه .
        </h1>
        <p>
             بناء تاريخ لأكثر من عقدين
          من العمليات الناجحة في مصر ، سوديك هي واحدة من الشركات الرائدة في مجال
          التطوير العقاري في البلاد شركات.
        </p>
        <div class="text-right">
          <button class="btn" (click)="scrollTo('contact')">اكتشف</button>
        </div>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-5 mt-2">
        <div class="form">
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="container">
              <div class="mt-2">
                <input
                  class="form-control shadow-none"
                  type="text"
                  placeholder="الاسم"
                  formControlName="name"
                />
                <app-fielderror class="text-right"
                  [displayError]="isFieldValid('name')"
                  errorMsg="رجاء ادخل اسما صحيحا"
                >
                </app-fielderror>
              </div>
              <div class="mt-2">
                <input
                  class="form-control shadow-none"
                  type="number"
                  placeholder="الهاتف"
                  formControlName="phone"
                />
                <app-fielderror class="text-right"
                  [displayError]="isFieldValid('phone')"
                  errorMsg="يرجى إدخال رقم هاتف صالح"
                ></app-fielderror>
              </div>
              <div class="mt-2">
                <input
                  class="form-control shadow-none"
                  type="email"
                  placeholder="البريد الالكتروني"
                  formControlName="email"
                />
                <app-fielderror class="text-right"
                  [displayError]="isFieldValid('email')"
                  errorMsg="يرجى إدخال عنوان بريد إلكتروني صالح"
                ></app-fielderror>
              </div>
            </div>
            <button type="submit" class="btn-form">أفتح حساب الأن</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="about" dir="rtl">
  <div class="container">
    <h1>
      <span style="color: #f15922">حول </span>
      سوديك
    </h1>
    <p>
      سوديك هي واحدة من الشركات الرائدة في مجال التطوير العقاري في البلاد شركات.
      مقرها في القاهرة ومدرج في البورصة المصرية الصرف ، تقدم سوديك إلى السوق على
      نطاق واسع حائز على جوائز التطورات التي تلبي حاجة مصر المتزايدة إلى إسكان
      عالي الجودة ، المساحات التجارية والتجزئة. نحن نفخر بأنفسنا على شغفنا
      التميز والالتزام بتعزيز علاقات طويلة الأمد مع شركائنا العملاء والمساهمين
      وشركاء الأعمال والموظفين ، مما ساعد ننمو إلى الشركة التي نحن عليها اليوم.
    </p>
  </div>
</section>

<section class="gallery">
  <div class="container">
    <h2>كلما عشت أكثر</h2>
    <h1>مشاريع سوديك</h1>
    <div class="row">
      <div class="col-md-4 mt-3">
        <img
          src="../../assets/images/gallery/1.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-8 ">
        <div class="row">
          <div class="col-md-7 px-2 mt-3">
            <img
              src="../../assets/images/gallery/2.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-md-5 px-2 mt-3">
            <img
              src="../../assets/images/gallery/3.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 px-2 mt-3">
            <img
              src="../../assets/images/gallery/4.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-md-7 px-2 mt-3">
            <img
              src="../../assets/images/gallery/5.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <img
      src="../../assets/images/gallery/6.png"
      alt=""
      class="img-fluid w-100 mt-3"
    />
  </div>
</section>

<section class="logos">
  <div class="container">
    <div class="row">
      <div class="col-md-2 col-6">
        <img src="../../assets/images/logos/2.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6">
        <img src="../../assets/images/logos/2-1.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-2.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/June-SalesPresentation-FINAL-compressed-2-1024x628.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-3.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-4.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-5.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6">
        <img src="../../assets/images/logos/2-6.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-7.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-8.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img src="../../assets/images/logos/2-9.png" alt="" class="img-fluid" />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-10.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6">
        <img
          src="../../assets/images/logos/2-11.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-12.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-13.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-14.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-15.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6">
        <img
          src="../../assets/images/logos/2-16.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-17.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-18.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-19.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-20.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6">
        <img
          src="../../assets/images/logos/2-21.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-22.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-23.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-24.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-25.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6">
        <img
          src="../../assets/images/logos/2-26.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-2 col-6 mt-2">
        <img
          src="../../assets/images/logos/2-27.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>

    <div class="text-center my-5">
      <iframe class="" width="70%" height="727"  src="https://www.youtube.com/embed/u8IsD4Ini3Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    </div>
  </div>
</section>

<section class="contact" dir="rtl">
  <div class="container">
    <h1>اتصل بنا</h1>
    <p>
      
      
      تأسست سوديك كشركة مساهمة عامة مع أكثر من 6000 المساهمين ورأس المال
      المدفوع 100 مليون جنيه مصري. الشركة اشترت أول قطعة أرض مساحتها 10 ملايين
      متر مربع في الشيخ زايد كجزء من رؤية مؤسسيها لتطوير مجتمع سكني غربي ضواحي
      القاهرة.
    </p>
    <div class="form">
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <div class="mt-4">
          <input
            class="form-control shadow-none"
            type="text"
            placeholder="الاسم"
            formControlName="name"
          />
          <app-fielderror class="text-right"
            [displayError]="isFieldValid('name')"
            errorMsg="رجاء ادخل اسما صحيحا"
          >
          </app-fielderror>
        </div>
        <div class="mt-4">
          <input
            class="form-control shadow-none"
            type="number"
            placeholder="الهاتف"
            formControlName="phone"
          />
          <app-fielderror class="text-right"
            [displayError]="isFieldValid('phone')"
            errorMsg="يرجى إدخال رقم هاتف صالح"
          ></app-fielderror>
        </div>
        <div class="mt-4">
          <input
            class="form-control shadow-none  "
            type="email"
            placeholder="البريد الالكتروني"
            formControlName="email"
          />
          <app-fielderror class="text-right"
            [displayError]="isFieldValid('email')"
            errorMsg="يرجى إدخال عنوان بريد إلكتروني صالح"
          ></app-fielderror>
        </div>
        <div class="mt-4">
          <textarea
            name="note"
            id=""
            rows="3"
            class="w-100 form-control shadow-none"
            placeholder="رسالة"
            formControlName="note"
          ></textarea>
        </div>
        <div class="text-center mt-5">
          <button type="submit" class="btn-form">اتصل بنا</button>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="footer" dir="rtl">
  <div class="container">
    <div class="d-flex align-items-center flex-wrap">
      <div>
        <img src="../../assets/images/main-logo.png" alt="" class="img-fluid" />
      </div>
      <div class="mr-md-auto mr-0 mt-md-0 mt-3">
        <p>اتصل بنا</p>
        <a href="tel:+201001040090">201001040090+</a>
      </div>
    </div>
  </div>
</section>
